@import "../../App.scss";

.page {
   width: 100%;
   height: 100%;
   position: absolute;
}

.tags {
   color: #ffd700;
   z-index: 1;
   opacity: .8;
   position: absolute;
   bottom: 0;
   left: 120px;
   font-size: 18px;
   font-family: 'La Belle Aurore';
}

.top-tags {
   bottom: auto;
   top: 35px;

}
.top-tag-html {
   bottom: auto;
   top: 15px;
   margin-left: -20px;

}

.bottom-tag-html {
   margin-left: -20px;
   z-index: 1;
}

.container {
   width: 100%;
   will-change: contents;
   height: 90%;
   min-height: 566px;
   position: absolute;
   opacity: 0;
   top: 5%;
   margin: 0 auto;
   z-index: 1;
   transform-style: preserve-3d;
   animation: fadeIn 1s forwards;
   animation-delay: 1s;
}

.about-page,
.contact-page,
.portfolio-page {
   .text-zone{
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;

      h1{
         font-size: 53px;
         font-family: 'Coolvetica';
         color: #34E4EA;
         font-weight: 400;
         margin-top: 0;
         position: relative;
         margin-bottom: 10px;
         left: 10px;
      }

      .before-text {
         font-family: 'La Belle Aurore';
         color : #ffd700;
         opacity: .8;
         font-size: 18px;
         position: absolute;
         margin-top: -20px;
      }

      .after-text {
         font-family: 'La Belle Aurore';
         color: #ffd700;
         opacity: .8;
         font-size: 18px;
      }

      p{
         font-size: 16px;
         color: #fff;
         font-family: sans-serif;
         font-weight: 300;
         min-width: fit-content;
         animation: pulse 1s;
         
         &:nth-of-type(1){
            animation-delay: 1.1s;
         }
         &:nth-of-type(2){
            animation-delay: 1.2s;
         }
         &:nth-of-type(3){
            animation-delay: 1.3s;
         }

         a {
            text-decoration: none;
            color: #34E4EA;

            &:hover {
               color: #ffd700;
            }
         }
      }

   .text-animate-hover {
      &:hover {
         color: #fff;
      }
   }

   }
}
@keyframes moveCloudsBack {
   from {background-position: 0 0;}
   to {background-position: 10000px 0;} 
}
@keyframes moveTwinkleBack {
   from {background-position: 0 0;}
   to {background-position: -10000px 5000px;} 
}
.stars, .twinkling, .clouds {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   display: block;
}

.stars {
   background: #000 url(../../assets/images/stars.png) repeat top center;
   z-index: -3;
}
.twinkling {
   background: transparent url(../../assets/images/twinkling.png) repeat top center;
   z-index: -2;
   animation: moveTwinkleBack 200s linear infinite;
}
.clouds {
   background: transparent url(../../assets/images/clouds.png);
   background-size: cover;
   z-index: -1;
   opacity: .4;
   animation: moveCloudsBack 200s linear infinite;
}

@include xl {
   .page {
      position: initial;
   }
   
   .container {
      position: initial;
      height: auto;
      min-height: auto;

      &.about-page,
      &.contact-page,
      &.portfolio-page {
         .text-zone {
           position: initial;
           transform: none;
           width: 100%;
           display: block;
           padding: 30px; 
           box-sizing: border-box;
         }
      }
   }
   .tags {
      display: none;
   }
}