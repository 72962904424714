@import "../../App.scss";

.contact-form {
   width: 100%;
   margin-top: 20px;

   ul {
      padding: 0;
      margin: 0;

      li {
         padding: 0;
         margin: 0;
         list-style: none;
         margin-bottom: 10px;
         overflow: hidden;
         display: block;
         position: relative;
         opacity: 0;
         animation: fadeInUp 2s 2s;
         animation-fill-mode: forwards;
         clear: both;
      }

      li.half {
         width: 49%;
         margin-left: 2%;
         float: left;
         clear: none;
         
         
         &:first-child{
            margin-left: 0;
         }
      }
   }
   input::placeholder {
      font-weight: bold;
      color: #fff;
      opacity: 0.6;
   }
   input[type='text'],
   input[type='email'] {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
   }

   textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      padding: 20px;
      min-height: 150px;
      box-sizing: border-box;

      &::placeholder {
         font-weight: bold;
         color: #fff;
         opacity: 0.6;
      }
   }
  .button {
      text-align: center;
      .flat-button {
         text-align: center;
         margin-right: auto;
         color: #ffffff;
         background: 0 0;
         font-family: 'Coolvetica';
         font-size: 16px;
         font-weight: 600;
         letter-spacing: 3px;
         text-decoration: none;
         padding: 5px 50px;
         border: 1px solid #115173;
         border-radius: 4px;
         transition: .2s;
         opacity: 0;
         animation-name: fadeInUp;
         animation-delay: 2.5s;
         animation-duration: 1.8s;
         animation-fill-mode: forwards;
         &:hover {
            background-color: #34E4EA;
            border-color: #34E4EA;
            color: #115173;
            transition: .2s;
         }
      }
   }
}
.info-map {
   position: absolute;
   background-color: #000;
   top: 50px;
   right: 30%;
   z-index: 999999;
   widows: 267px;
   padding: 20px;
   color: #fff;
   font-family: 'Helvetica';
   font-size: 17px;
   font-weight: 300;
   opacity: 0;
   animation: fadeIn 1s 1.5s;
   animation-fill-mode: forwards;

   span {
      font-size: 16px;
      display: block;
      padding-top: 20px;
      color: #34E4EA;
   }
}

.map-wrap {
   background-color: rgba(17,18,115, 0.1);
   float: right;
   width: 53%;
   height: 100%;
}

.leaflet-container {
   width: 100%;
   height: 100%;
   position: relative;
   opacity: 0;
   animation: backInRight 1s 1.2s;
   animation-fill-mode: forwards;

   .leaflet-popup-content {
      font-size: 17px;
   }
}

@include xl {
   .map-wrap {
      float : none;
      width: 100%;
      height: 400px;
      margin: 0;
   }
   .info-map {
      display: none;
   }
}
