@import "../../App.scss";

.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  height: 100%;
  overflow: auto;

  .text-zone {
    top: 20%;
  }

  .project-wrap {
    position: absolute;
    top: 30%;

    .image-container {
      display: flex;
      gap: 30px 50px;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-bottom: 100px;

      .image-box {
        flex: 1 1 20%;
        height: 400px;
        max-width: calc(25% - 85px);
        overflow: hidden;
        position: relative;
        border-radius: 10px;

        .portfolio-image {
          z-index: 2;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .content {
          position: absolute;
          height: 100%;
          width: 100%;
          bottom: -110%;
          z-index: 3;
          padding: 0 20px;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 0,
            rgba(0, 0, 0, 1)
          );
          display: flex;
          align-items: flex-end;

          .text-wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            height: 50%; 

            .title {
              margin-bottom: 0;
              margin-top: 0;
              color: #fff;
              font-size: 24px;
              font-weight: 500;
              line-height: 24px;
              flex: 1 1 100%;
              font-family: 'Coolvetica';
            }

            .description {
              font-size: 14px;
              color: #fff;
              font-weight: 700;
              margin-bottom: 5px;
              flex: 1 1 100%;
            }

            .btn {
              margin-top: 15px;
              margin-bottom: 30px;
              padding: 0 23px;
              height: 40px;
              line-height: 34px;
              border: 2px solid #34e4ea;
              background-color: transparent;
              color: #34e4ea;
              font-size: 14px;
              border-radius: 2px;
              text-transform: uppercase;
              font-weight: 700;
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              cursor: pointer;

              &:hover {
                transform: translateY(-3px);
                background: #34e4ea;
                color: #115173;
              }
            }
          }
        }

        &:after {
          content: '';
          background: linear-gradient(180deg, #34e4ea, #000);
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          opacity: 0;
        }

        &:hover::after {
          opacity: 0.85;
        }
        &:hover .content {
          bottom: 0;
        }
      }
    }
  }
}


@include xl {
  .container.portfolio-page {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    overflow: visible;
    

    h1.page-title {
      margin-left: 20px;
      margin-top: 20px;
    }
    .project-wrap {
      position: initial;
      .image-container {
        flex-wrap: wrap;
        .image-box {
          height: 200px;
          max-width: calc(50% - 25px);
          flex: 1 1 50%; 
          object-fit: none;

          .content {
            width: unset;
            align-items: center;
            padding: 0 10px;
            .text-wrap {
              justify-content: center;
              .title {
                font-size: 18px;
                text-align: center;
              }
              .description {
                display: none;
              }
              .btn {
                height: auto;
                line-height: unset;
                margin-bottom: 0;
                padding: 4px 20px;
              }
           }
          }
        }
      }
    }

  }
}