@import "../../../App.scss";

.logo-container {
   z-index: 0;
   width: 566px;
   height: 555px;
   position: absolute;
   top: 0;
   right: 15%;
   bottom: 0; 
   left: auto;
   margin: auto;
   opacity: 0;
   @include xl {
      width: 300px;
      right: 31%;
      top: 20px;
      height: unset;
   }
   svg {
      width: 100%;
      height: auto;
      bottom: 0;
      transform: rotateZ(30deg) !important;
   }
   .solid-logo {
      position: absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 0;
      transform: rotateZ(30deg) !important;
      z-index: 1;
   }

}

.svg-container {
   stroke: #34e4ea;
   stroke-width: 3px;
}